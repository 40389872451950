<script setup lang="ts">
import { BanIcon, QuestionMarkCircleIcon } from '@heroicons/vue/outline';
import { ChartBarIcon, MusicNoteIcon, PlayIcon } from '@heroicons/vue/solid';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import type { PropType } from 'vue';
import ProfileHandle from '~/components/ProfileHandle.vue';
import { formatXchPrice } from '~/utilities/chia-display-utils';
import { getUrlForNft } from '~/utilities/url-slugs';

const props = defineProps({
  nft: { type: Object as PropType<any>, required: true },
  compact: Boolean as PropType<boolean>,
  unlinked: { type: Boolean as PropType<boolean>, default: () => false },
  alwaysShowText: { type: Boolean as PropType<boolean>, default: () => false },
  target: { type: String as PropType<string>, default: '' },
});
const { nft, compact, unlinked, alwaysShowText } = toRefs(props);

const collection = computed(() => {
  if (nft.value.collection) {
    return nft.value.collection;
  } else if (!nft.value.collection_id) {
    return undefined;
  }
  return {
    id: nft.value.collection_id,
    name: nft.value.collection_name,
    sensitive_content: nft.value.collection_sensitive_content,
    blocked_content: nft.value.collection_blocked_content,
  };
});

const creator = computed(() => {
  if (nft.value.creator) {
    return nft.value.creator;
  } else if (!nft.value.creator_id) {
    return undefined;
  }
  return {
    id: nft.value.creator_id,
    encoded_id: nft.value.creator_encoded_id,
    avatar_uri: nft.value.creator_avatar_uri,
    name: nft.value.creator_name,
    verification_state: nft.value.creator_verification_state,
  };
});

const creatorAddress = computed(() => {
  if (nft.value.creator_address) {
    return nft.value.creator_address;
  } else {
    return {
      encoded_id: nft.value.creator_address_encoded_id,
    };
  }
});

const owner = computed(() => {
  if (nft.value.owner) {
    return nft.value.owner;
  } else if (!nft.value.owner_id) {
    return undefined;
  }
  return {
    id: nft.value.owner_id,
    encoded_id: nft.value.owner_encoded_id,
    avatar_uri: nft.value.owner_avatar_uri,
    name: nft.value.owner_name,
  };
});

const ownerAddress = computed(() => {
  if (nft.value.owner_address) {
    return nft.value.owner_address;
  } else {
    return {
      encoded_id: nft.value.owner_address_encoded_id,
    };
  }
});
const name = computed(() => nft.value.data?.metadata_json.name || nft.value.name || 'Unnamed');
const dataType = computed(() => nft.value.data?.data_type || nft.value.data_type);
const thumbnailUri = computed(() => nft.value.data?.thumbnail_uri || nft.value.thumbnail_uri);
const isSensitiveContent = computed(() => collection.value?.sensitive_content || nft.value.sensitive_content);
const edition = computed(() => ({
  number: nft.value.data?.edition_number || nft.value.edition_number || 1,
  total: nft.value.data?.edition_total || nft.value.edition_total || 1,
}));

const auction = computed(() => {
  if (
    nft.value?.auction &&
    differenceInMilliseconds(parseISO(nft.value.auction.start), new Date()) < 1000 * 3600 * 24 &&
    differenceInMilliseconds(parseISO(nft.value.auction.overtime.end), new Date()) > 0
  ) {
    return {
      ...nft.value.auction,
      start: parseISO(nft.value.auction.start),
      end: parseISO(nft.value.auction.overtime.end),
      active: differenceInMilliseconds(parseISO(nft.value.auction.start), new Date()) < 0,
    };
  } else {
    return undefined;
  }
});
</script>

<template>
  <div
    :class="auction ? 'bg-black dark:bg-neutral-200' : 'bg-white dark:bg-neutral-800'"
    class="shadow-card hover:shadow-card-hover group relative flex flex-col overflow-hidden rounded-lg transition duration-300 ease-in-out betterhover:hover:-translate-y-0.5"
  >
    <div class="aspect-h-1 aspect-w-1 relative">
      <div v-if="!nft" class="flex items-center justify-center">
        <div class="h-full w-full animate-pulse bg-neutral-100 dark:bg-neutral-800"></div>
      </div>
      <div v-else-if="nft.is_blocked || collection?.blocked_content" class="flex items-center justify-center">
        <BanIcon class="h-1/2 w-1/2 text-neutral-600 dark:text-neutral-300" />
      </div>
      <div v-else-if="creator?.verification_state === 2" class="flex items-center justify-center">
        <BanIcon class="h-1/2 w-1/2 text-red-600" />
      </div>
      <img
        v-else-if="thumbnailUri"
        :alt="`${name} image`"
        :src="thumbnailUri"
        loading="lazy"
        :class="[isSensitiveContent ? 'blur-lg' : '']"
        class="h-full w-full object-cover object-center"
      />
      <div v-else-if="dataType === 3" class="flex items-center justify-center">
        <PlayIcon class="h-1/2 w-1/2 text-neutral-400 dark:text-neutral-500" />
      </div>
      <div v-else-if="dataType === 4" class="flex items-center justify-center">
        <MusicNoteIcon class="h-1/2 w-1/2 text-neutral-400 dark:text-neutral-500" />
      </div>
      <div v-else class="flex items-center justify-center">
        <QuestionMarkCircleIcon class="h-1/2 w-1/2 text-neutral-400 dark:text-neutral-500" />
      </div>
      <NuxtLink
        v-if="nft"
        :to="unlinked ? undefined : getUrlForNft(nft)"
        :target="target"
        :class="alwaysShowText ? 'bg-black/30' : 'betterhover:group-hover:bg-black/30'"
        class="absolute inset-0 z-20 flex h-full w-full flex-col items-center justify-start bg-transparent transition duration-300 ease-in-out"
      >
        <div
          :class="[
            compact ? 'px-3 py-2' : 'px-6 py-4',
            alwaysShowText ? '' : 'opacity-0 betterhover:group-hover:opacity-100',
          ]"
          class="flex w-full flex-grow transition duration-100 ease-in-out"
        >
          <span v-if="collection" :class="compact ? 'text-xs' : 'text-lg'" class="font-bold text-white">{{
            collection.name
          }}</span>
        </div>
        <div
          :class="[compact ? 'p-2' : 'p-4', alwaysShowText ? '' : 'opacity-0 betterhover:group-hover:opacity-100']"
          class="flex w-full items-end justify-between gap-2 transition duration-100 ease-in-out"
        >
          <span :class="compact ? 'text-sm' : 'text-xl'" class="font-bold text-white">{{ name }}</span>
          <span v-if="edition.total !== 1" class="whitespace-nowrap text-sm font-bold text-white"
            >{{ edition.number }} / {{ edition.total || '∞' }}</span
          >
        </div>
      </NuxtLink>
    </div>
    <div :class="compact ? 'p-2' : 'p-4'" class="flex h-full flex-1 flex-col">
      <div class="flex flex-1 flex-col gap-4">
        <div class="flex flex-1 flex-row items-start justify-between">
          <div>
            <div v-if="!nft">
              <div class="h-6 w-3/4 rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
            </div>
            <ProfileHandle
              v-else-if="creator"
              :profile="creator"
              :avatar-dimension="compact ? '1.25rem' : '1.75rem'"
              :named-classes="`font-bold ${
                auction ? 'text-neutral-200 dark:text-neutral-700' : 'text-neutral-700 dark:text-neutral-200'
              } ${compact ? 'text-xs' : ''}`"
              :unnamed-classes="`tracking-tight font-mono ${
                auction ? 'text-neutral-100 dark:text-neutral-800' : 'text-neutral-900 dark:text-neutral-50'
              } ${compact ? 'text-xs' : ''}`"
            />
            <AddressHandle v-else :address="creatorAddress" />
          </div>
          <div v-if="!compact && nft?.openrarity_rank" class="flex-shrink-0">
            <NuxtLink
              to="https://docs.mintgarden.io/mintgarden/rarity/"
              target="_blank"
              :class="auction ? 'text-neutral-100 dark:text-neutral-800' : 'text-neutral-900 dark:text-neutral-50'"
              class="flex items-center gap-1 rounded-lg border-2 px-2 py-0.5 text-sm font-semibold"
            >
              <ChartBarIcon class="inline h-4 w-4" />
              {{ nft.openrarity_rank }}
            </NuxtLink>
          </div>
        </div>
        <div class="flex flex-1 flex-row items-end justify-between gap-1">
          <div>
            <div v-if="!nft">
              <div class="h-6 w-3/4 rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
            </div>

            <div v-else-if="auction" class="flex flex-col items-start">
              <span class="text-xs font-semibold text-neutral-400 dark:text-neutral-500">{{
                !auction.active || (auction.highest_bid?.xch_price || 0) < (auction.reserve_xch_price || 0)
                  ? $t('collection.reserve')
                  : $t('collection.highest_bid')
              }}</span>
              <div class="flex items-center gap-2">
                <div v-if="auction.active" class="relative inline-flex">
                  <span :class="compact ? 'h-2 w-2' : 'h-3 w-3'" class="flex items-center justify-center">
                    <span
                      class="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-white opacity-50 dark:bg-black"
                    ></span>
                    <span class="relative inline-flex h-2 w-2 rounded-full bg-neutral-200 dark:bg-neutral-700"></span>
                  </span>
                </div>
                <span class="font-bold text-white dark:text-black" :class="compact ? 'text-xs' : ''">{{
                  auction.active && auction.highest_bid?.xch_price
                    ? formatXchPrice(auction.highest_bid?.xch_price, true)
                    : auction.reserve_xch_price
                    ? formatXchPrice(auction.reserve_xch_price)
                    : $t('collection.no_bid')
                }}</span>
              </div>
            </div>
            <div v-else-if="nft.price && !nft.is_blocked" class="flex flex-col items-start">
              <span class="text-xs font-semibold text-neutral-500 dark:text-neutral-400">Price</span
              ><span :class="compact ? 'text-xs' : ''" class="font-bold text-black dark:text-white">
                <span v-if="nft.token_id === 'xch'">{{ formatXchPrice(nft.price) }}</span>
                <span class="flex items-center" v-else
                  >{{ nft.price
                  }} {{nft.token_code}}
                </span>
              </span>
            </div>
          </div>
          <div v-if="!compact">
            <div v-if="!nft">
              <div class="h-6 w-3/4 rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
            </div>
            <div v-else-if="auction?.active" class="flex flex-col items-start">
              <span class="text-xs font-semibold text-neutral-400 dark:text-neutral-500">{{
                $t('collection.ends_in')
              }}</span>
              <AuctionCountdownSmall :end="auction.end" />
            </div>
            <div v-else-if="auction" class="flex flex-col items-start">
              <span class="text-xs font-semibold text-neutral-400 dark:text-neutral-500">{{
                $t('collection.starts_in')
              }}</span>
              <AuctionCountdownSmall :end="auction.start" />
            </div>
            <ProfileHandle v-else-if="owner" :profile="owner" />
            <AddressHandle v-else :address="ownerAddress" />
          </div>
          <div v-else-if="compact && auction?.active" class="flex-shrink-0 text-xs">
            <span class="text-xs font-semibold text-neutral-400 dark:text-neutral-500">{{
              $t('collection.ends_in')
            }}</span>
            <AuctionCountdownSmall :end="auction.end" />
          </div>
          <div v-else-if="compact && auction" class="flex-shrink-0 text-xs">
            <span class="text-xs font-semibold text-neutral-400 dark:text-neutral-500">{{
              $t('collection.starts_in')
            }}</span>
            <AuctionCountdownSmall :end="auction.start" />
          </div>
          <div v-else-if="compact && nft?.openrarity_rank" class="flex-shrink-0">
            <NuxtLink
              to="https://docs.mintgarden.io/mintgarden/rarity/"
              target="_blank"
              :class="auction ? 'text-neutral-100 dark:text-neutral-800' : 'text-neutral-900 dark:text-neutral-50'"
              class="flex items-center gap-1 rounded-lg border-2 px-1 py-0.5 text-xs font-semibold"
            >
              <ChartBarIcon class="inline h-3 w-3" />
              {{ nft.openrarity_rank }}
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
